<template>
    <div class="card title">
        <div class="card-body title-body pb-2 pl-2 pr-2">
            <h3 class="tile-title is-line mb-2">
                Danh mục
                <span class="btn-add float-right pr-2" @click="create">
                    <i class="icon16-plus-circle-gadient"></i>
                </span>
            </h3>
            <div class="row">
                <div class="col-12">
                    <g-loading v-if="firstLoading" />
                    <div v-else-if="listLoading" class="text-center text-primary">
                        <b-spinner></b-spinner>
                    </div>
                    <div v-if="!listLoading && !itemCount" class="text-center text-danger">
                        Danh mục trống
                    </div>
                    <div v-if="!listLoading && itemCount" class="title-template-table  table-responsive">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Tên nhân viên</th>
                                    <th class="text-right">Hành động</th>
                                </tr>
                            </thead>
                            <draggable v-if="!isEmpty" v-model="items" @change="draggableChange" tag="tbody">
                                <tr v-for="item in items" :key="item.id" >
                                    <td><i :class="item.icon"></i>&nbsp;{{item.name}}</td>
                                    <td class="text-right">
                                <g-button :loading="updateLoading" size="xs" variant="outline-info" class="ml-1" icon="pencil" @click='update(item.id)'/>
                                <g-button :loading="delLoading" size="xs" variant="outline-danger" class="ml-1" icon="trash-fill" @click='del(item.id)' />
                                <button class="btn btn-outline-success ml-3 pointer btn-xs">
                                    <b-icon icon="arrows-move" aria-hidden="true"></b-icon>
                                </button>
                                </td>
                                </tr>
                            </draggable>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer title-footer clearfix" v-if="itemCount > filterFormModel.size">
            <div class="row">
                <div class="col-lg-6 form-inline">
                    Đang xem {{firstItemOfPage}}-{{lastItemOfPage}}/{{itemCount}}
                </div>
                <div class="col-lg-6 form-inline">
                    <b-pagination size="sm" class="pagination pagination-sm float-right pb-0 mb-0"
                                  v-model="filterFormModel.page" :per-page="filterFormModel.size" :total-rows="itemCount" @change="setPage">
                    </b-pagination>
                </div>
            </div>
        </div>

        <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus hide-header>
            <form-input label="Icon" :model="formModel" :errors="formErrors" attribute="icon" placeholder="fa fa-*"></form-input>
            <form-input label="Tên danh mục" :model="formModel" :errors="formErrors" attribute="name" placeholder="Tên danh mục"></form-input>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import draggable from 'vuedraggable';

    export default {
        components: {draggable},
        mixins: [list, crud],
        data: function () {
            return {
                service: '/auth-item-group',
                reloadBus: 'reloadSecurityAction',
                defaultFilterFormModel: {
                    page: 1,
                    size: 50,
                },
                defaultFormModel: {
                    name: '',
                    icon: ''
                }
            }
        },
        methods: {
            draggableChange: function () {
                this.items.forEach((item, index) => {
                    item.position = index;
                    this.$service({url: `${this.service}/update?id=${item.id}`, method: 'put', data: item}).catch(error => {
                        if (error.response.data.msg) {
                            this.$swal({
                                text: error.response.data.msg,
                                icon: 'error'
                            });
                        }
                    });
                });
            },
        },
        mounted() {
        }
    }
</script>