<template>
    <div class="row">
        <div class="col-md-12">
            <action />
        </div>
        <div class="col-md-12">
            <group />
        </div>
    </div>
</template>

<script>
    import Group from './Group';
    import Action from './Action';

    export default {
        components: {Group, Action},
    }
</script>