<template>
    <div class="card title">
        <div class="card-body title-body pb-2 pl-2 pr-2">
            <h3 class="tile-title is-line mb-2">
                <div class="row">
                    <div class="col-md-4">
                        Hành động
                    </div>
                    <div class="col-md-8">
                        <b-tabs class="c-tabs-button mr-2 float-right" small pills v-model="tab">
                            <b-tab title="Hành động"></b-tab>
                            <b-tab title="Dịch vụ"></b-tab>
                            <b-tab title="Đã xoá"></b-tab>
                        </b-tabs>
                    </div>
                </div>
            </h3>
            <div class="row">
                <div class="col-12">
                    <g-loading v-if="firstLoading" />
                    <div v-else-if="tab===0 || tab===1" class="title-template-table  table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Chức năng</th>
                                    <th>Tên hành động</th>
                                    <th>
                                        Danh mục
                                    </th>
                                    <th>
                                        Thứ tự
                                        <i class="fa fa-question-circle ml-2" v-b-tooltip="`Thứ tự hiển trị trên phần điều hướng trang quản trị`"></i>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="(val, key) in funcs" :key="key">
                                <tr :data-id="key" class="table-secondary" v-if='(tab == 0 && routers.includes(key)) || (tab == 1 && !routers.includes(key))'>
                                    <td class="text-left" >
                                        <span :class="(routers.includes(key)? 'c-signal is-success' : 'c-signal')">
                                            {{key}}
                                        </span>
                                    </td>
                                    <td><form-input :model="val" attribute="alias" class="mb-0" /></td>
                            <td><form-select v-if='tab == 0' :model="val" attribute="groupId" :options="groupOptions" class="mb-0"></form-select></td>
                            <td><form-input  v-if='tab == 0' :model="val" attribute="position" class="mb-0" placeholder="Nhập thứ tự" /></td>                        
                            <td>
                                <button :disabled="saveLoading" class="btn btn-outline-gray btn-sm" type="button" @click="update(key, val, 1)">
                                    <b-spinner v-if="saveLoading" small></b-spinner>
                                    <i v-else class="fa fa-save"></i> Lưu
                                </button>
                            </td>
                            </tr>
                            <template v-for="permission in val">
                                <tr :key="permission" v-if='(tab == 0 && routers.includes(permission)) || (tab == 1 && !routers.includes(permission))'>
                                    <td class="text-left pl-5" >
                                        <span :class="(routers.includes(permission)? 'c-signal is-success' : 'c-signal')">
                                            {{permission}}
                                        </span>
                                    </td>
                                    <td><form-input :model="subChilds[permission]" attribute="alias" class="mb-0" /></td>
                                <td><form-select v-if='tab == 0' :model="subChilds[permission]" attribute="groupId" :options="groupOptions" class="mb-0"></form-select></td>
                                <td><form-input  v-if='tab == 0' :model="subChilds[permission]" attribute="position" class="mb-0" placeholder="Nhập thứ tự" /></td>                            
                                <td>
                                    <button :disabled="saveLoading"  class="btn btn-outline-gray btn-sm" type="button" @click="update(permission, subChilds[permission], 2)">
                                        <b-spinner v-if="saveLoading" small></b-spinner>
                                        <i v-else class="fa fa-save"></i> Lưu
                                    </button>
                                </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class="title-template-table table-responsive">
                        <div v-if="listLoading" class="text-center text-primary">
                            <b-spinner></b-spinner>
                        </div>
                        <div v-if="!listLoading && notExists.length === 0" class="text-center text-danger">
                            Không tìm thấy kết quả nào
                        </div>
                        <table v-if="!listLoading && notExists.length > 0" class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Chức năng</th>
                                    <th>Tên hành động</th>
                                    <th>Danh mục</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in notExists" :key="item.name">
                                <tr :data-id="item.name">
                                    <td class="text-left" >
                                        <span :class="(routers.includes(item.name)? 'c-signal is-success' : 'c-signal')">
                                            {{item.name}}
                                        </span>
                                    </td>
                                    <td>{{item.alias}}</td>
                                    <td>{{getGroupName(item.groupId)}}</td>
                                    <td>
                            <g-button :loading="delLoading" size="xs" variant="outline-danger" class="ml-1" icon="trash-fill" @click='del(item.name)' />
                            </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import crud from '@/mixins/CRUD';

    export default {
        mixins: [crud],
        data: function () {
            return {
                service: '/auth-item',
                listLoading: false,
                firstLoading: true,
                ignores: ["/index", "/", "/profile", "/login", "/logout", '/forgot', '/reset'],
                funcs: {},
                datagroup: [],
                items: [],
                itemChilds: [],
                subChilds: {},
                groupOptions: [{value: 0, text: "-- Chọn danh mục --"}],
                routers: [],
                notExists: [],
                tab: 0
            };
        },
        methods: {
            vueRouter: function (actions) {
                let routers = this.$router.options.routes;
                this.routers = [];
                routers.forEach(router => {
                    if (this.ignores.indexOf(router.path) === -1) {
                        let path = router.path.substring(1).split("/");
                        let actionName = path[0];
                        if (actionName && !actions[actionName]) {
                            actions[actionName] = [];
                        }
                        if (routers.indexOf(actionName) === -1) {
                            this.routers.push(actionName);
                        }
                        if (actions[actionName]) {
                            if (router.children) {
                                router.children.forEach(children => {
                                    if (children.path && children.path.indexOf(":") === -1) {
                                        let childrenName = `${actionName}_${children.path}`;
                                        this.routers.push(childrenName);
                                        if (actions[actionName].indexOf(childrenName) === -1) {
                                            actions[actionName].push(childrenName);
                                        }

                                        if (children.children) {
                                            children.children.forEach(level3 => {
                                                if (level3.path && level3.path.indexOf(":") === -1) {
                                                    let level3Name = `${actionName}_${children.path}/${level3.path}`;
                                                    this.routers.push(level3Name);
                                                    if (actions[actionName].indexOf(level3Name) === -1) {
                                                        actions[actionName].push(level3Name);
                                                    }
                                                }
                                            });
                                        }

                                    }
                                });
                            }
                        }
                    }
                });
                return actions;
            },
            load: function () {
                this.showForm = true;
                let loadingDelay = _.delay(() => {
                    this.listLoading = true;
                }, 250);
                this.$service.get(this.service, {
                    params: this.filterFormModel
                }).then(response => {
                    this.firstLoading = false;
                    this.funcs = this.vueRouter(response.data.funcs);
                    this.datagroup = response.data.datagroup;
                    this.items = response.data.items;
                    this.itemChilds = response.data.itemChilds;
                    this.subChilds = {};

                    Object.keys(response.data.funcs).forEach(key => {
                        this.funcs[key].alias = '';
                        this.funcs[key].groupId = '0';
                        for (let j = 0; j < this.items.length; j++) {
                            if (this.items[j].name === key) {
                                this.funcs[key].position = this.items[j].position;
                                this.funcs[key].alias = this.items[j].alias;
                                this.funcs[key].groupId = "" + this.items[j].groupId;
                            }
                        }
                        for (let i = 0; i < this.funcs[key].length; i++) {
                            this.subChilds[this.funcs[key][i]] = {
                                alias: '',
                                groupId: "0",
                                position: 0
                            };
                            for (var j = 0; j < this.items.length; j++) {
                                if (this.items[j].name === this.funcs[key][i]) {
                                    this.subChilds[this.funcs[key][i]].position = this.items[j].position;
                                    this.subChilds[this.funcs[key][i]].alias = this.items[j].alias;
                                    this.subChilds[this.funcs[key][i]].groupId = "" + this.items[j].groupId;
                                }
                            }
                        }
                    });

                    let keys = Object.keys(this.funcs);
                    Object.values(this.funcs).forEach(item => {
                        keys = keys.concat(item);
                    });
                    this.notExists = [];
                    this.items.forEach(item => {
                        if (keys.indexOf(item.name) === -1) {
                            this.notExists.push(item);
                        }
                    });

                    this.groupOptions = [{value: 0, text: "-- Chọn danh mục --"}];
                    this.datagroup.forEach(group => {
                        this.groupOptions.push({value: group.id, text: group.name});
                    });
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    clearTimeout(loadingDelay);
                    this.listLoading = false;
                });
            },
            update: function (key, model, type) {
                this.formModel = {
                    id: key,
                    type: type,
                    alias: model.alias,
                    name: key,
                    groupId: model.groupId,
                    position: model.position
                };
                this.save();
            },
            getGroupName: function (groupId) {
                let group = this.groupOptions.find(o => {
                    return o.value === groupId;
                });
                return group && group.value !== 0 ? group.text : '';
            }
        },
        mounted() {
            this.load();
            this.$bus.$on('reloadSecurityAction', this.load);
        },
        beforeDestroy: function () {
            this.$bus.$off('reloadSecurityAction');
        },
    }
</script>